import { PageOptions } from '@graphcommerce/framer-next-pages'
import { PageMeta, StoreConfigDocument } from '@graphcommerce/magento-store'
import {
  GetStaticProps,
  Separator,
  icon404,
  IconSvg,
  MetaRobots,
  useStorefrontConfig,
} from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Box, Container, Typography, Link } from '@mui/material'
import router from 'next/router'
import React from 'react'
import { LayoutNavigation, LayoutNavigationProps } from '../components'
import { LayoutDocument } from '../components/Layout/Layout.gql'
import { graphqlSsrClient, graphqlSharedClient } from '../lib/graphql/graphqlSsrClient'

type Props = Record<string, unknown>
type GetPageStaticProps = GetStaticProps<LayoutNavigationProps, Props>

function RouteNotFoundPage() {
  const storeB2B = useStorefrontConfig().magentoStoreCode.includes('b2b')
  const metaRobots = ['noindex'] as MetaRobots[]

  const links = [
    <Link onClick={() => router.back()} href='#' underline='hover'>
      <Trans id='Back' />
    </Link>,
    <Link key={0} href='/' color='primary' underline='hover'>
      <Trans id='Store home' />
    </Link>,
    <Link key={1} href='/account' color='primary' underline='hover'>
      <Trans id='Account' />
    </Link>,
    <Link key={2} href='/service' color='primary' underline='hover'>
      <Trans id='FAQ' />
    </Link>,
  ]

  return (
    <>
      <PageMeta
        title={i18n._('Page not found')}
        metaRobots={storeB2B ? ['noindex', 'nofollow'] : metaRobots}
      />
      <Container maxWidth='sm'>
        <Box textAlign='center' mt={16} mb={16}>
          <IconSvg src={icon404} size='xxl' />
          <Typography variant='h3' component='h1' gutterBottom>
            <Trans id='Whoops our bad...' />
          </Typography>
          <Typography variant='body1'>
            <Trans id="We couldn't find the page you were looking for" />
          </Typography>
          <Trans id='Or follow these links to get you back on track!' />
          <Box mb={8}>
            {links.map((link, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={index}>
                {index > 0 && <Separator />}
                {link}
              </React.Fragment>
            ))}
          </Box>
        </Box>
      </Container>
    </>
  )
}

RouteNotFoundPage.pageOptions = {
  Layout: LayoutNavigation,
} as PageOptions

export default RouteNotFoundPage

export const getStaticProps: GetPageStaticProps = async ({ locale }) => {
  const client = graphqlSharedClient(locale)
  const staticClient = graphqlSsrClient(locale)
  const conf = client.query({ query: StoreConfigDocument })
  const layout = staticClient.query({ query: LayoutDocument, fetchPolicy: 'cache-first' })

  return {
    props: {
      ...(await layout).data,
      up: { href: '/', title: 'Home' },
      apolloState: await conf.then(() => client.cache.extract()),
    },
    revalidate: 60 * 20,
  }
}
